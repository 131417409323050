import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/jsou-krabicky-vhodne-pro-kojici-zeny.jpg'
import styles from './post-grid.module.css'

const KrabickyVhodneKojiciZeny = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Jsou krabičky vhodné pro kojící ženy? | NutritionPro'}
        description={
          'V úvodu je dobré zmínit, jak by vlastně měl jídelníček kojící maminky vypadat. Tvorba mateřského mléka je energeticky velmi náročná a je na ni spotřebováno velké množství energie. Tělo matky se na kojení připravuje již v průběhu těhotenství a tvoří si tukové zásoby, ze kterých bude moct při kojení čerpat. Při optimální hmotnosti se doporučuje přibrat okolo 12 kg. Pokud vaše váha toto číslo nepřesáhne, nebudou tukové zásoby stačit a je nutné jíst více, než za normálních okolností, dokonce i více, než během těhotenství. Je však dobré si během kojení hlídat hmotnost, nemělo by totiž docházet k přibírání.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/jsou-krabicky-vhodne-pro-kojici-zeny">
            Jsou krabičky vhodné pro kojící ženy?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Jsou krabičky vhodné pro kojící ženy?"
              date="16.04.2023"
            />
            <div>
              <h5 className={styles.postTitle}>
                Vhodný jídelníček pro kojící ženy
              </h5>
              <p className={styles.postText}>
                V úvodu je dobré zmínit, jak by vlastně měl jídelníček kojící
                maminky vypadat. Tvorba mateřského mléka je{' '}
                <b>energeticky velmi náročná</b> a je na ni spotřebováno velké
                množství energie. Tělo matky se na kojení připravuje{' '}
                <b>již v průběhu těhotenství</b> a tvoří si tukové zásoby, ze
                kterých bude moct při kojení čerpat. Při optimální hmotnosti se
                doporučuje přibrat okolo 12 kg. Pokud vaše váha toto číslo
                nepřesáhne, nebudou tukové zásoby stačit a je nutné{' '}
                <b>jíst více</b>, než za normálních okolností, dokonce i více,
                než během těhotenství. Je však dobré si během kojení{' '}
                <b>hlídat hmotnost</b>, nemělo by totiž docházet k přibírání.
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="dívka s dítětem"
              />

              <p className={styles.postText}>
                Strava je při kojení <b>velmi důležitá</b>. Může ovlivnit jak
                zdraví matky, tak dítěte. Mateřské mléko je víceméně{' '}
                <b>stabilní</b>, co se složení týče, mění se akorát podle potřeb
                miminka. Do určité míry však <b>může být stravou</b> matky jeho
                složení <b>ovlivněno</b>.
              </p>

              <p className={styles.postText}>
                Co lze především ovlivnit, je <b>složení tuků</b> mateřského
                mléka. Je proto vhodné volit <b>kvalitní zdroje tuků</b>, jako
                jsou rostlinné oleje, ořechy či avokádo. Živočišné tuky (máslo),
                kokosový a palmový olej je vhodné zařadit jen <b>výjimečně</b>.
              </p>

              <p className={styles.postText}>
                Podíváme se také na <b>bílkoviny</b>. Denní potřeba bílkovin se
                v průběhu kojení <b>zvyšuje až o 50 %</b> (tedy 1,2 - 1,5 g/kg
                tělesné hmotnosti). <b>Vhodnými zdroji</b> bílkovin jsou libové
                maso, mléko a mléčné výrobky (ideálně kysané), vejce a také
                rostlinné zdroje, jako jsou luštěniny, které zároveň obsahují
                potřebnou vlákninu.
              </p>

              <p className={styles.postText}>
                Pro matku i miminko je důležitý především{' '}
                <b>dostatečný příjem vitaminu C, A, B6 a B12.</b> Pro matku je
                také prospěšný příjem{' '}
                <b>kyseliny listové (vitamin B9), vápník, železo a zinek</b>. To
                jsou mikronutrienty, které se do mateřského mléka dostanou ze
                zásob v těle matky, miminko tedy strádat nebude. Pro kojící je
                však příjem těchto látek podstatný.
              </p>

              <p className={styles.postText}>
                <b>Jídelníček kojících</b> by měl být především <b>pestrý</b>.
                Volte různé zdroje tuků i bílkovin, jezte ovoce a zeleninu ke
                každému jídlu, dbejte na dostatek celozrnných potravin a
                nezapomínejte na mléčné výrobky. Také byste neměli opomíjet{' '}
                <b>pitný režim</b>, který je v průběhu kojení velmi důležitý.
                Mateřského mléka se za 1 den vyprodukuje zhruba <b>800 ml</b>,
                je proto nutné <b>navýšit i příjem tekutin</b>, a to alespoň o
                0,5 l. Ideálně v podobě <b>čisté vody.</b>
              </p>

              <p className={styles.postText}>
                <b>TIP:</b>{' '}
                <i>
                  Jak na zdravý jídelníček se dozvíte v{' '}
                  <a
                    href="https://nutritionpro.cz/blog/zdrave-stravovani/"
                    target="_blank"
                  >
                    tomto článku.
                  </a>
                </i>
              </p>

              <p className={styles.postText}>
                V průběhu kojení se <b>vyhněte konzumaci alkoholu</b>, většímu
                množství kávy, pozor na <b>syrové potraviny</b>, jako je třeba
                sushi, a nedostatečně umyté ovoce a zeleninu. Uzené a smažené
                potraviny konzumujte pouze s mírou, stejně tak silně kořeněná
                jídla. Co se týče <b>potenciálních alergenů</b>, nebojte se a
                určitě je z potravy <b>nevylučujte</b>. Potraviny jako jsou
                sója, ořechy či vejce konzumujte s mírou a pouze sledujte, jak
                miminko na co reaguje. Mělo by se totiž skrze mléko seznámit{' '}
                <b>s co nejvíce potravinami</b>.
              </p>

              <p className={styles.postText}>
                Nadýmání a plynatost miminka a jejich spojitost se stravou matky{' '}
                <b>nebyla studiemi prokázána</b>. Proto je nutné{' '}
                <b>sledovat, co jíte</b> a jestli to miminku náhodou nedělá
                problémy.
              </p>

              <h5 className={styles.postTitle}>
                Je krabičková dieta vhodná pro kojící?
              </h5>
              <p className={styles.postText}>
                <b>
                  Ano!{' '}
                  <a href="https://nutritionpro.cz/" target="_blank">
                    Krabičková dieta
                  </a>{' '}
                  je vhodná i pro kojící maminky.
                </b>{' '}
                Mimo to, že obsahuje dostatečné množství bílkovin, sacharidů,
                tuků, vitaminu i minerálních látek, které jsou v období kojení{' '}
                <b>velmi důležité</b>, je také <b>časově i finančně výhodná</b>.
                Místo vaření a nákupů se tak můžete{' '}
                <b>naplno věnovat vašemu miminku.</b>
              </p>

              <p className={styles.postText}>
                Jak jsme si řekli, je důležité při kojení{' '}
                <b>sledovat, co konzumujete</b>, abyste si případně mohli
                zaznamenat, co nedělá vašemu miminku dobře. I v tom vám{' '}
                <b>krabičky pomohou</b>, jelikož <b>budete vždy vědět</b>, co
                jste kdy snědli a co vás čeká.
              </p>

              <p className={styles.postText}>
                Krabičky vám také mohou pomoci <b>vrátit se do formy</b>. Při
                kojení se jednoznačně{' '}
                <b>vyhýbejte veškerým přísným redukčním dietám</b>, při kterých
                se mohou do mléka uvolňovat látky s negativním vlivem na
                miminko.{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  S krabičkami
                </a>{' '}
                budete mít{' '}
                <b>jistý dostatečný příjem nejen živin, ale i energie</b>.
                Můžete si vybrat hned z několika programů podle toho, zda se
                chcete stravovat jen pomocí krabiček, nebo si chcete například
                přidat vlastní svačinky. <b>Nezapomeňte také na pohyb</b>. Při
                kojení je vhodné zařadit mírnou fyzickou aktivitu.
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default KrabickyVhodneKojiciZeny
